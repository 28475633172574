.tile {
    /* min-width: 3.3%;
    width: 5%;
    height: 5%; */
    border-width: 1px;
    border-color: black;
    border-style: solid;
}

.tile-container {
    display: grid;
}

.tile-break {
    flex-basis: 100%;
    height: 0;
}

.tileElement {
    width: 50%;
    height: 50%;
    border-radius: 50%;
    margin: auto;
    margin-top: 25%;
}

.tile-selected {
    border-color: white;
}