body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.body-container {
  margin-left: 16rem;
  width: calc(100% - 16rem);
  background-color: #282c34;
  height: 100vh;
  padding: 1rem;
  overflow: auto;
}

.margin-1 {
  margin: 1% !important;
}

.margin-2 {
  margin: 2% !important;
}

.margin-3 {
  margin: 3% !important;
}

.margin-4 {
  margin: 4% !important;
}

.margin-5 {
  margin: 5% !important;
}

.margin-6 {
  margin: 6% !important;
}

.max-w-1 {
  max-width: 1% !important;
}

.max-w-2 {
  max-width: 2% !important;
}

.max-w-3 {
  max-width: 3% !important;
}

.max-w-4 {
  max-width: 4% !important;
}

.max-w-5 {
  max-width: 5% !important;
}

.max-w-6 {
  max-width: 6% !important;
}

.max-w-7 {
  max-width: 7% !important;
}

.max-w-8 {
  max-width: 8% !important;
}

.max-w-9 {
  max-width: 9% !important;
}

.max-w-10 {
  max-width: 10% !important;
}

.max-w-15 {
  max-width: 15% !important;
}

.max-w-20 {
  max-width: 20% !important;
}

.max-w-25 {
  max-width: 25% !important;
}

.max-w-30 {
  max-width: 30% !important;
}

.max-w-35 {
  max-width: 35% !important;
}

.max-w-40 {
  max-width: 40% !important;
}

.max-w-50 {
  max-width: 50% !important;
}

.max-w-100 {
  max-width: 100% !important;
}

.min-w-1 {
  min-width: 1% !important;
}

.min-w-2 {
  min-width: 2% !important;
}

.min-w-3 {
  min-width: 3% !important;
}

.min-w-4 {
  min-width: 4% !important;
}

.min-w-5 {
  min-width: 5% !important;
}

.min-w-6 {
  min-width: 6% !important;
}

.min-w-7 {
  min-width: 7% !important;
}

.min-w-8 {
  min-width: 8% !important;
}

.min-w-9 {
  min-width: 9% !important;
}

.min-w-10 {
  min-width: 10% !important;
}

.min-w-15 {
  min-width: 15% !important;
}

.min-w-20 {
  min-width: 20% !important;
}

.min-w-25 {
  min-width: 25% !important;
}

.min-w-30 {
  min-width: 30% !important;
}

.min-w-35 {
  min-width: 35% !important;
}

.min-w-40 {
  min-width: 40% !important;
}

.min-w-50 {
  min-width: 50% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.max-h-1 {
  max-height: 1% !important;
}

.max-h-2 {
  max-height: 2% !important;
}

.max-h-3 {
  max-height: 3% !important;
}

.max-h-4 {
  max-height: 4% !important;
}

.max-h-5 {
  max-height: 5% !important;
}

.max-h-6 {
  max-height: 6% !important;
}

.max-h-7 {
  max-height: 7% !important;
}

.max-h-8 {
  max-height: 8% !important;
}

.max-h-9 {
  max-height: 9% !important;
}

.max-h-10 {
  max-height: 10% !important;
}

.max-h-15 {
  max-height: 15% !important;
}

.max-h-20 {
  max-height: 20% !important;
}

.max-h-25 {
  max-height: 25% !important;
}

.max-h-30 {
  max-height: 30% !important;
}

.max-h-35 {
  max-height: 35% !important;
}

.max-h-40 {
  max-height: 40% !important;
}

.max-h-50 {
  max-height: 50% !important;
}

.max-h-100 {
  max-height: 100% !important;
}

.min-h-1 {
  min-height: 1% !important;
}

.min-h-2 {
  min-height: 2% !important;
}

.min-h-3 {
  min-height: 3% !important;
}

.min-h-4 {
  min-height: 4% !important;
}

.min-h-5 {
  min-height: 5% !important;
}

.min-h-6 {
  min-height: 6% !important;
}

.min-h-7 {
  min-height: 7% !important;
}

.min-h-8 {
  min-height: 8% !important;
}

.min-h-9 {
  min-height: 9% !important;
}

.min-h-10 {
  min-height: 10% !important;
}

.min-h-15 {
  min-height: 15% !important;
}

.min-h-20 {
  min-height: 20% !important;
}

.min-h-25 {
  min-height: 25% !important;
}

.min-h-30 {
  min-height: 30% !important;
}

.min-h-35 {
  min-height: 35% !important;
}

.min-h-40 {
  min-height: 40% !important;
}

.min-h-50 {
  min-height: 50% !important;
}

.min-h-100 {
  min-height: 100% !important;
}